// font-family:"Dita";
// regular: 400
@import url("https://use.typekit.net/yws8sie.css");

/*
		This font software is the property of Commercial Type.

		You may not modify the font software, use it on another website, or install it on a computer.

		License information is available at http://commercialtype.com/eula
		For more information please visit Commercial Type at http://commercialtype.com or email us at info[at]commercialtype.com

		Copyright (C) 2024 Schwartzco Inc.
		License 2406-HXZZOM
*/
@font-face {
	font-family: 'Graphik Web';
	src: url('/frontend/fonts/Graphik-Regular-Web.woff2') format('woff2'),
		url('/frontend/fonts/Graphik-Regular-Web.woff') format('woff');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

.Graphik-Regular-Web {
	font-family: 'Graphik Web';
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Graphik Web';
	src: url('/frontend/fonts/Graphik-Medium-Web.woff2') format('woff2'),
		url('/frontend/fonts/Graphik-Medium-Web.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

.Graphik-Medium-Web {
	font-family: 'Graphik Web';
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Graphik Web';
	src: url('/frontend/fonts/Graphik-MediumItalic-Web.woff2') format('woff2'),
		url('/frontend/fonts/Graphik-MediumItalic-Web.woff') format('woff');
	font-weight: 500;
	font-style: italic;
	font-display: swap;
}

.Graphik-MediumItalic-Web {
	font-family: 'Graphik Web';
	font-weight: 500;
	font-style: italic;
}