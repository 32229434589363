// ==========================================================================
// Variables
// ==========================================================================
/*--------------------------------------------------------------
## Colors
--------------------------------------------------------------*/
$color__white: #ffffff;
$color__black: #000000;
$color__black-soft: #282828;
$color__white-50: #8e8e8e;
$color__black-80: rgba(0, 0, 0, 0.8);
$color__black-70: rgba(0, 0, 0, 0.7);
$color__black-65: rgba(0, 0, 0, 0.65);
$color__black-60: rgba(0, 0, 0, 0.6);
$color__black-40: rgba(0, 0, 0, 0.4);
$color__black-35: rgba(0, 0, 0, 0.35);
$color__black-30: rgba(0, 0, 0, 0.3);
$color__black-25: rgba(0, 0, 0, 0.25);
$color__black-20: rgba(0, 0, 0, 0.2);
$color__black-10: rgba(0, 0, 0, 0.1);
$color__black-05: rgba(0, 0, 0, 0.05);
$color__grey-medium: #707070;
$color__grey-light: #f5f4f4;
$color__turquoise: #2a978e;
$color__orange: #ff9717;
$color__blue: #4598e7;
$color__lilas: #9180e9;
$color__red-pink: #ea2126;
$gradient-04: linear-gradient(0deg,
		rgba(119, 176, 156, 1) 0%,
		rgba(88, 150, 190, 1) 97%,
		rgba(88, 150, 190, 1) 99%);
/*--------------------------------------------------------------
## typography
--------------------------------------------------------------*/
$fa-font-path: '~@fortawesome/fontawesome-pro/webfonts';
$font__fa-familly: Font Awesome\6 Pro;
$font-main: 'Graphik Web', helvetica, arial, sans-serif;
$font__main: 'Graphik Web', helvetica, arial, sans-serif;
$font__display: 'dita', helvetica, arial, sans-serif;
$font-mono: monospace;
$font__heading: 'Graphik Web', helvetica, arial, sans-serif;
$font__size-body: 16px;
$font__size-14: 0.875rem;
$font__size-13: 0.8125rem;
$font__size-12: 0.75rem;
$font__code: Monaco, Consolas, 'Andale Mono', 'DejaVu Sans Mono', monospace;
$font__pre: 'Courier 10 Pitch', Courier, monospace;
$font__line-height-body: 1.2857em;
// $font__line-height-pre: 1.6;
$font__line-height-reg: 1.3em;
$font__weight-bold: 700;
// $font__weight-semibold: 600;
$font__weight-medium: 500;
$font__weight-regular: 400;
$font__top-position-correction: 0.11em;