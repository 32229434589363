/*--------------------------------------------------------------
## Animate acronyme logo
--------------------------------------------------------------*/
.pim-logo-accronyme-link {

	.acronyme-path-01,
	.acronyme-path-02,
	.acronyme-path-03 {
		fill: $color__white;
	}
	
	&:hover {

		.acronyme-path-01,
		.acronyme-path-02,
		.acronyme-path-03 {
			animation-duration: 4s;
			animation-iteration-count: infinite;
			animation-name: acronyme-anymation-01;
		}
		
		.acronyme-path-02 {
			animation-delay: 0.5s;
		}
		
		.acronyme-path-03 {
			animation-delay: 0.25s;
		}
	}
}

@keyframes acronyme-anymation-01 {
	0% {
		fill: $color__white;
	}
	
	22% {
		fill: $color__turquoise;
	}
	
	44% {
		fill: $color__orange;
	}
	
	66% {
		fill: $color__blue;
	}
	
	88% {
		fill: $color__lilas;
	}
	
	100% {
		fill: $color__white;
	}
}

.animation-iteration-bounce {
	opacity: 0;
	transform: translate(-50%, 0px);
	animation-duration: 8s;
	animation-timing-function: ease;
	animation-delay: 0s;
	animation-iteration-count: 1;
	animation-direction: normal;
	animation-fill-mode: none;
	animation-play-state: running;
	animation-name: bouncehome;
	animation-timeline: auto;
	animation-range-start: normal;
}

@keyframes bouncehome {
	0% {
		opacity: 0;
		transform: translate(-50%, 0px);
	}
	
	10% {
		opacity: 1;
		transform: translate(-50%, -16px);
	}
	
	20% {
		transform: translate(-50%, 0px);
	}
	
	30% {
		transform: translate(-50%, -16px);
	}
	
	40% {
		transform: translate(-50%, 0px);
	}
	
	50% {
		transform: translate(-50%, -16px);
	}
	
	60% {
		transform: translate(-50%, 0px);
	}
	
	70% {
		transform: translate(-50%, -16px);
	}
	
	80% {
		transform: translate(-50%, 0px);
	}
	
	90% {
		transform: translate(-50%, -16px);
		opacity: 1;
	}
	
	100% {
		transform: translate(-50%, 0px);
		opacity: 0;
	}
}