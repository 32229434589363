/*creating usefull color classes*/
/*******************************************************
    Text colors
*******************************************************/
// .color-white {
// 	color: $color__white;
// }
//
// .color-black {
// 	color: $color__black;
// }
/*******************************************************
    Background colors
*******************************************************/
// .back-color-white {
// 	background-color: $color__white;
// }
//
// .back-color-black {
// 	background-color: $color__black;
// }
.gradient_hor-01 {
	background: linear-gradient(to right, #e68d4f 0%, #e68d4f 11%, #a97dd0 80%, #a97dd0 100%);
}

.gradient_hor-02 {
	background: linear-gradient(to right, #77b09c 0%, #77b09c 6%, #5896be 95%, #5896be 100%);
}

.gradient_hor-03 {
	background: linear-gradient(to right, #aa7dce 0%, #6195c3 100%);
}

.gradient_hor-04 {
	background: linear-gradient(to right, #6195c3 0%, #aa7dce 100%);
}

.gradient_hor-card-01,
.gradient-ai {
	background: linear-gradient(to right, #77B09C 0%, #77B09C 10%, #65A1AF 100%);
}

.gradient_hor-card-02,
.gradient-stim {
	background: linear-gradient(to right, #AA7DCE 0%, #808BC8 100%);
}

.gradient_hor-card-03,
.gradient-transition {
	background: linear-gradient(to right, #E68D4F 0%, #E68D4F 10%, #C48496 100%);
}

/*******************************************************
	Gradients multiply layers
*******************************************************/
/******************************
	Gradient home
******************************/
.gradient_home_orange {
	background: linear-gradient(225deg, rgba(238, 143, 63, 1) 0%, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0) 100%);
}

.gradient_home_aqua {
	background: linear-gradient(135deg, rgba(59, 166, 150, 1) 0%, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0) 100%);
}

.gradient_home_violet {
	background: linear-gradient(300deg, rgba(159, 121, 234, 1) 0%, rgba(159, 121, 234, 0) 60%, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0) 100%);
}

.gradient_home_blue {
	background: linear-gradient(25deg, rgba(49, 148, 179, 1) 0%, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0) 100%);
}

/******************************
	Gradient home
******************************/
.gradient_ai_blue {
	background: linear-gradient(135deg, rgba(96, 203, 183, 1) 0%, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0) 100%);
}

.gradient_ai_violet {
	background: linear-gradient(320deg, rgba(141, 146, 176, 1) 0%, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0) 100%);
}

.gradient_ai_orange {
	background: linear-gradient(225deg, rgba(200, 172, 110, 1) 0%, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0) 100%);
}

.gradient_ai_aqua {
	background: linear-gradient(25deg, rgba(56, 161, 147, 1) 0%, rgba(255, 255, 255, 0) 70%, rgba(255, 255, 255, 0) 70%, rgba(255, 255, 255, 0) 100%);
}

/******************************
	Gradient stim
******************************/
.gradient_transi_top-left {
	background: linear-gradient(100deg, rgba(186, 202, 127, 1) 0%, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0) 100%);
}

.gradient_transi_bottom-right {
	background: linear-gradient(340deg, rgba(224, 134, 148, 1) 0%, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0) 100%);
}

.gradient_transi_top-right {
	background: linear-gradient(210deg, rgba(239, 143, 59, 1) 0%, rgba(239, 143, 59, 0) 70%, rgba(255, 255, 255, 0) 70%, rgba(255, 255, 255, 0) 100%);
}

.gradient_transi_bottom-left {
	background: linear-gradient(25deg, rgba(149, 167, 188, 1) 0%, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0) 100%);
}

/******************************
	Gradient Transition
******************************/
.gradient_stim_top-left {
	background: linear-gradient(100deg, rgba(128, 160, 153, 1) 0%, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0) 100%);
}

.gradient_stim_bottom-right {
	background: linear-gradient(340deg, rgba(139, 96, 249, 1) 0%, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0) 100%);
}

.gradient_stim_top-right {
	background: linear-gradient(210deg, rgba(204, 134, 132, 1) 0%, rgba(239, 143, 59, 0) 70%, rgba(255, 255, 255, 0) 70%, rgba(255, 255, 255, 0) 100%);
}

.gradient_stim_bottom-left {
	background: linear-gradient(25deg, rgba(86, 148, 225, 1) 0%, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0) 100%);
}

/*******************************************************
	ALT colors
*******************************************************/
.bg-blue-alt {
	background-color: #6EABE5;
}

.bg-green-alt {
	background-color: #8AC6C1;
}

.bg-grey-medium {
	background-color: #d9d9d9;
}

.bg-turquoise {
	background-color: #2A978E;
}

.bg-orange {
	background-color: #FF9717;
}

.bg-violet {
	background-color: #9180E9;
}